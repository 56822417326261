import React from "react";
import "./hero.css";

interface Props {
  refProp: React.RefObject<HTMLDivElement>;
  setIsCheckoutPopupOpen: (b: boolean) => void;
}

export default function Hero({ refProp, setIsCheckoutPopupOpen }: Props) {
  return (
    <div className="hero" id="about" ref={refProp}>
      <div className="hero1">
        <img
          className="fox"
          src={require("../../assets/XVA Fox 1.png")}
          alt=""
        />

        <div className="section-1">
          <div className="heading-1">$SPLRG UTILITY TOKENS</div>
          <div className="heading-2">
            <p>
              Buy and HODL?
              <br />
              That's so yesterday
            </p>

            <p>
              Buy. HODL. Use.
              <br />
              That's the future
            </p>

            <p>Buy a piece of tomorrow – today</p>
          </div>
        </div>
      </div>

      <br />
      <br />
      <br />

      <div className="hero2">
        <div className="heading-big center">Why $SPLRG</div>
        <div className="section">
          <p>
            Our mission is to flip the script on exclusivity,
            <br />
            jazz up blockchain utility,
            <br />
            and rewrite the playbook for luxury access.
          </p>

          <p>
            By tokenising real world assets into unique, tradable NFTs,
            <br />
            we are reshaping how luxury assets are collected.
            <br />
            Everyone can $SPLRG.
          </p>
        </div>

        <img src={require("../../assets/why-splrg.png")} alt="" />
      </div>

      <br />
      <br />
      <br />

      <div className="hero2">
        <div className="heading-big center">What you get when you $SPLRG</div>
        <div className="section">
          The $SPLRG market offering will initially be divided into 3 categories
          assets, collectibles & experiences. Our mission is to only provide
          highly premium, limited edition, and customized Real World Assets for
          the $SPLRG customer.
        </div>

        <img src={require("../../assets/splrg-rwa-nfts.png")} alt="" />
        <br />

        <div className="heading center">Luxury Assets.</div>
        <img src={require("../../assets/luxary-assets.png")} alt="" />
        <div className="section">
          With over $1.5 million worth of premium wines in our warehouse, we are
          now expanding our partnerships to include other luxury assets. To
          qualify for tokenization, these assets must have a well-documented
          price history and demonstrate yield potential. This approach extends
          beyond wines to include premium spirits, luxury bags, and high-end
          watches.
        </div>
        <br />

        <div className="heading center">Collectibles.</div>
        <img src={require("../../assets/collectables.png")} alt="" />
        <div className="section">
          We’re collaborating with renowned brands to craft exclusive, highly
          sought-after collectibles, while also developing our own line of
          $SPLRG-branded products. Collaboration is at the heart of what we do,
          spanning both the fashion industry and the broader Web3 space. By
          teaming up with luxury brands and key players in the Web3 community,
          we combine expertise and influence to create standout, one-of-a-kind
          products. These partnerships not only enhance the $SPLRG brand but
          also unlock new opportunities for growth and visibility.
        </div>
        <br />

        <div className="heading center">Experiences.</div>
        <img src={require("../../assets/experiences.png")} alt="" />
        <div className="section">
          We’re actively in talks with event companies, exclusive membership
          communities, and luxury hotel groups to curate unique, high-end
          experiences exclusively for the $SPLRG ecosystem. Our mission is to
          amplify the value of $SPLRG by offering extraordinary, life-enhancing
          opportunities that enrich and elevate the lives of our community
          members.
        </div>
      </div>

      <br />
      <br />
      <br />

      <div className="hero2">
        <div className="heading-big center">Key $SPLRG Announcements</div>
        <div className="section" style={{ width: "95%" }}>
          <p>
            <b>Dec 18, 2024</b> is the moment we've all been waiting for.
            <br />
            $SPLRG is officially listed on <b>XT.com</b> - and this is just the
            beginning. More listings are on the way!
            <br />
            But that's not all... <b>We dropped something BIG</b>.
            <br />
            To celebrate, we're releasing <b>limited-edition RWA NFTs</b>{" "}
            exclusively on X.
            <br />
            These NFTs are rare, exclusive, and yours for the taking.
            <br />
            <br />
            <b>
              Don't miss your shot - click{" "}
              <a
                href="https://x.com/xva_collective"
                target="_blank"
                rel="noreferrer"
                style={{ color: "#ff5c02" }}
              >
                here
              </a>{" "}
              to claim your NFTs!
            </b>
            <br />
            <br />
            Once they're gone, they're GONE.
            <br />
            Follow our{" "}
            <a
              href="https://t.me/+PBRv24_N5OUwYmM1"
              target="_blank"
              rel="noreferrer"
            >
              Telegram
            </a>{" "}
            and{" "}
            <a
              href="https://discord.gg/cv6HHe4CgU"
              target="_blank"
              rel="noreferrer"
            >
              Discord
            </a>{" "}
            pages for more insights.
          </p>
        </div>
      </div>

      <br />
      <br />
      <br />

      <div className="hero2">
        <div className="heading-big center">$SPLRG Tokenomics</div>
        <div className="section">
          <p>
            Our total market cap stands at 500 million $SPLRG tokens, with an
            initial release of 50 million tokens on the Solana ecosystem.
            <br />
            Holding $SPLRG unlocks exclusive access to our NFTs and a world of
            premium privileges.
          </p>
        </div>

        <br />

        <img
          className="big-image"
          style={{ backgroundColor: "white", maxWidth: "65%" }}
          src={require("../../assets/tokenomics.png")}
          alt=""
        />

        <br />

        <div className="section">
          <p>
            For a quick summary of what $SPLRG is all about{" "}
            <a
              href="https://xva.asia/info1.pdf"
              target="_blank"
              rel="noreferrer"
            >
              click here
            </a>
            <br />
            For a more detailed White Paper on $SPLRG{" "}
            <a
              href="https://xva.asia/info2.pdf"
              target="_blank"
              rel="noreferrer"
            >
              click here
            </a>
            <br />
            To check out our FAQ’s on $SPLRG{" "}
            <a href="/FAQs.pdf" target="_blank" rel="noreferrer">
              click here
            </a>
            <br />
            Everything You Need to Know About $SPLRG Tokens & NFTs{" "}
            <a href="/tokens-and-nfts.pdf" target="_blank" rel="noreferrer">
              click here
            </a>
          </p>
        </div>
      </div>
    </div>
  );
}
